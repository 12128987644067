import React, { useState } from 'react'
import { House } from '../../../types/HomeDesigns'
import { ThemeOptions, PercentageDTO } from '../../../types'
import RoomList from '../../Common/RoomList'
import './style.css'
import ButtonGroup from './ButtonGroup'
import DepositCalculator from '../../Common/DepositCalculator'
import formatHousePrice from '../../../utils/formatHousePrice'
import calculateMonthlyFee from '../../../utils/calculateMonthlyFee'
import SiteLink from '../../Common/SiteLink'
import HyperLink from '../../Common/HyperLink'

interface HouseCardInfoProps {
    house: House
    showDepositCalculator?: boolean
    showHomeOnly?: boolean
    showCtaButton?: boolean
    options?: ThemeOptions
    active: number
    setActive: Function
}

export const HouseCardInfo = (Props: HouseCardInfoProps) => {
    const {
        house,
        options,
        active,
        showDepositCalculator = false,
        showCtaButton = false,
        showHomeOnly = false,
        setActive,
    } = Props
    const percentages: PercentageDTO[] = [
        { label: '5%', value: 0.95 },
        { label: '10%', value: 0.9 },
        { label: '20%', value: 0.8 },
    ]
    const [percentageSelection, setPercentageSelection] = useState<number>(0)
    const weeklyFee = Math.round(
        parseInt(calculateMonthlyFee(house.price)) *
            percentages[percentageSelection].value
    )
    const housePrice = formatHousePrice(house.price)
    const buttonList: string[] = ['Home only']
    if (showHomeOnly) buttonList.push('House and Land')
    return (
        <div className="house-card-info">
            <div className="top">
                <h3 style={{ color: options.theme.color.primary }}>
                    {house.name}
                </h3>
                <RoomList rooms={house.rooms} />
            </div>
            <ButtonGroup
                buttonList={buttonList}
                active={active}
                setActive={setActive}
            />
            {house.package_only ? (
                <div className="bottom">
                    <h2 className="red big-text">House & Land Package Only</h2>
                </div>
            ) : (
                <div className="bottom">
                    <span className="price">
                        from <span className="red big-text">${housePrice}</span>
                    </span>
                    <span className="monthly-price">
                        or <span className="red">${weeklyFee}</span> a week
                        {
                            <HyperLink
                                label="*"
                                style={{ color: options.theme.color.primary }}
                                onHoverStyle={{
                                    color: options.theme.color.secondary,
                                }}
                                href={'https://porterdavis.com.au/disclaimer/'}
                            />
                        }
                    </span>
                </div>
            )}
            {showCtaButton ? (
                <SiteLink
                    label={'View house and land packages'}
                    to="/house-and-land-packages"
                    className="cta-button"
                    style={{
                        color: 'white',
                        backgroundColor: options.theme.color.primary,
                        border: `1px solid ${options.theme.color.primary}`,
                    }}
                    onHoverStyle={{
                        color: options.theme.color.primary,
                        backgroundColor: 'transparent',
                        border: `1px solid ${options.theme.color.primary}`,
                    }}
                />
            ) : (
                <></>
            )}
            {showDepositCalculator && !house.package_only ? (
                <DepositCalculator
                    values={percentages}
                    setValue={setPercentageSelection}
                    selectedValue={percentageSelection}
                />
            ) : (
                <></>
            )}
        </div>
    )
}
