import React from 'react'
import './style.css'
import Button from './Button'

interface ButtonGroupProps {
    buttonList: string[]
    active: number
    setActive: Function
}

export default ({ buttonList, active, setActive }: ButtonGroupProps) => {
    return (
        <div className="button-group">
            <ul>
                {buttonList.map((button, index) => (
                    <Button
                        key={index}
                        label={button}
                        active={active === index}
                        setActive={() => setActive(index)}
                    />
                ))}
            </ul>
        </div>
    )
}
