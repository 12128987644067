import React from 'react'
import './style.css'
import { ThemeOptions } from '../../../types'
import { navigate } from 'gatsby'

interface RelatedHouseListingProps {
    houses: {
        id: string
        lot: string
        image: string
        address: string
        name: string
        location: { area: string; suburb: string }
    }[]
    active: number
    options?: ThemeOptions
}

export default ({ houses, active, options }: RelatedHouseListingProps) => {
    if (!houses) return <></>
    return (
        <div
            className="related-house-listing"
            style={{
                maxHeight: active === 1 ? 270 : 0,
            }}
        >
            <ul>
                {houses.map(house => (
                    <li
                        onClick={() =>
                            navigate(`/house-and-land-packages/${house.id}`)
                        }
                    >
                        <div className="img-container">
                            <img
                                src={house.image}
                                alt={'related house image'}
                                width={100}
                                height={50}
                            />
                        </div>
                        <div>
                            {/* <span
                                className="name"
                                style={{
                                    color: options.theme.color.primary,
                                }}
                            >
                                {house.name}
                            </span> */}
                            <span
                                className="area"
                                style={{
                                    color: options.theme.color.secondary,
                                }}
                            >
                                Lot {house.lot} {house.address},
                            </span>
                            <span className="suburb" style={{ color: 'grey' }}>
                                {house.location.suburb}
                            </span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
