import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import PageHeader from '../components/PageHeader'
import HomeDesigns from '../views/HomeDesigns'
import {
    House,
    HomeDesignRequest,
    HousePackageRequest,
    HousePackage,
} from '../types/HomeDesigns'
import mapHomeDesignRequest from '../utils/mapHomeDesignRequest'
import mapHousePackageRequest from '../utils/mapHousePackageRequest'
import SubscribeForm from '../components/SubscribeForm'
import { SiteConfig } from '../types'

const backgroundImage = require('../images/Rectangle.png')

const defaultTheme = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

interface HomeDesignsProps {
    pageContext: {
        homeDesigns: HomeDesignRequest[]
        housePackages: HousePackageRequest[]
        config: SiteConfig
    }
}

export default ({ pageContext }: HomeDesignsProps) => {
    const mappedHouses: House[] = pageContext.homeDesigns
        .map(homeDesign => mapHomeDesignRequest(homeDesign))
        .sort((a, b) => parseInt(a.price) - parseInt(b.price))
    const mappedHousePackages: HousePackage[] = pageContext.housePackages.map(
        housePackage => mapHousePackageRequest(housePackage)
    )

    return (
        <ThemeProvider value={defaultTheme}>
            <Layout>
                <SEO title="Home Designs" />
                <PageHeader
                    backgroundImage={backgroundImage}
                    header={
                        'A curated range of homes, designed for first home buyers'
                    }
                    subHeader={'Choose your lifestyle'}
                />
                <HomeDesigns
                    houses={mappedHouses}
                    housePackages={mappedHousePackages}
                />
                <SubscribeForm
                    theme={defaultTheme}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
