import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../../../context/theme'

interface ButtonProps {
    label: string
    active?: boolean
    setActive: Function
}

export default ({ label, active, setActive }: ButtonProps) => {
    const theme = useContext(ThemeContext)
    const [hover, setHover] = useState<boolean>(false)
    return (
        <li
            style={{
                backgroundColor: active ? '#fff' : 'rgb(247, 247, 247)',
                color: hover || active ? theme.color.primary : 'grey',
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setActive()}
        >
            {label}
        </li>
    )
}
