import React, { useState } from 'react'
import { Link } from 'gatsby'
import './style.css'

interface SiteLinkProps {
    to: string
    label: string
    style?: any
    onHoverStyle?: any
    className?: string
}

export default (Props: SiteLinkProps) => {
    const { to, label, style, onHoverStyle, className } = Props
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <Link
            to={to}
            className={`common site-link ${className}`}
            style={onHover ? onHoverStyle : style}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            {label}
        </Link>
    )
}
