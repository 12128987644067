import React, { useState } from 'react'
import { House, HousePackage } from '../../types/HomeDesigns'
import { ThemeOptions } from '../../types'
import './style.css'
import { Link } from 'gatsby'
import { HouseCardInfo } from './HouseCardInfo'
import RelatedHouseListing from './RelatedHouseListing'

interface HouseCardProps {
    house: House
    options?: ThemeOptions
    relatedHouses: HousePackage[]
}

export default ({ house, options, relatedHouses }: HouseCardProps) => {
    const [active, setActive] = useState<number>(0)
    const [onButtonHover, setOnButtonHover] = useState<boolean>(false)
    const relatedHouseListing = relatedHouses
        .map(related => ({
            id: related.id,
            lot: related.lotNumber,
            image: related.previewImage,
            address: related.streetName,
            name: related.name,
            location: { area: 'Melbourne', suburb: related.suburb },
        }))
        .slice(0, 4)
    return (
        <li className="house-card container-fluid">
            <div className="image-container">
                <img
                    src={house.backgroundImage}
                    height="100%"
                    width="100%"
                    style={{ objectFit: 'cover' }}
                    alt={`house-image-${house.name}`}
                />
            </div>
            <div className="text-container">
                <HouseCardInfo
                    house={house}
                    options={options}
                    active={active}
                    setActive={setActive}
                    showCtaButton={true}
                    showHomeOnly={
                        relatedHouseListing && relatedHouseListing.length > 0
                    }
                />
                <RelatedHouseListing
                    houses={relatedHouseListing}
                    active={active}
                    options={options}
                />
                <Link
                    to={`/home-designs/${house.id}`}
                    onMouseEnter={() => setOnButtonHover(true)}
                    onMouseLeave={() => setOnButtonHover(false)}
                    className="view-button"
                    style={{
                        backgroundColor: onButtonHover
                            ? options.theme.color.primary
                            : options.theme.color.secondary,
                    }}
                >
                    View Home
                </Link>
            </div>
            {/* <Floorplan floorplan={{}} show={showFloorplan} /> */}
        </li>
    )
}
