import React, { useContext, useState, useEffect } from 'react'
import HouseCard from '../../components/HouseCard'
import { ThemeContext } from '../../context/theme'
import { House, HousePackage } from '../../types/HomeDesigns'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import SortButton from '../../components/SortButton'
import { SortState } from '../../types'

interface HomeDesignsProps {
    houses: House[]
    housePackages: HousePackage[]
}

export default ({ houses, housePackages }: HomeDesignsProps) => {
    const theme = useContext(ThemeContext)
    const [sortState, setSortState] = useState<number>(0)
    const [sortedHouses, setSortedHouses] = useState<House[]>(houses)

    const setNewSortState = (newSortState: SortState) => {
        if (newSortState === SortState.LowToHigh)
            sortedHouses.sort((a, b) => parseInt(a.price) - parseInt(b.price))
        else if (newSortState === SortState.HighToLow)
            sortedHouses.sort((a, b) => parseInt(b.price) - parseInt(a.price))
        setSortedHouses(sortedHouses)
        setSortState(newSortState)
    }

    return (
        <>
            {/* <Filter options={{ theme }} /> */}
            <section
                className="home-designs"
                style={{
                    backgroundImage: `linear-gradient(${theme.color.primary}, ${theme.color.secondary})`,
                }}
            >
                <div className="sorting-container">
                    <p>Viewing {houses.length} Home Designs</p>
                    <SortButton
                        options={{ theme }}
                        sortState={sortState}
                        setSortState={setNewSortState}
                    />
                </div>
                {sortedHouses.length ? (
                    <ReactCSSTransitionGroup
                        transitionName="house-card-animation"
                        component="ul"
                        transitionAppear={true}
                        transitionAppearTimeout={2000}
                        transitionEnterTimeout={2000}
                        transitionLeaveTimeout={2000}
                    >
                        {sortedHouses.map((house, index) => {
                            const _relatedHousePackages = housePackages.filter(
                                housePackage =>
                                    housePackage.name
                                        .split('-')[0]
                                        .toLowerCase() ===
                                    (
                                        house.design_name + house.design_size
                                    ).toLowerCase()
                            )
                            return (
                                <HouseCard
                                    key={index}
                                    house={house}
                                    options={{ theme }}
                                    relatedHouses={_relatedHousePackages}
                                />
                            )
                        })}
                    </ReactCSSTransitionGroup>
                ) : (
                    <div className="loading">
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            color={theme.color.secondary}
                            className="animation"
                        />
                    </div>
                )}
            </section>
        </>
    )
}
